import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const fetchAllDefinitionReq = createAsyncThunk(
  "company/fetchAllDefinitionReq",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/get_all_definitions`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addDefinitionReq = createAsyncThunk(
  "company/addDefinitionReq",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/admin/add_definition`, params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: response.data.message });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteDefinitionReq = createAsyncThunk(
  "company/deleteDefinitionReq",
  async (definition_id, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_definition/${definition_id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: response.data.message });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateDefinitionReq = createAsyncThunk(
  "company/updateDefinitionReq",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_definition/${params.id}`, params.data)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: response.data.message });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
