import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const addComanyTable = createAsyncThunk(
  "companyAddTable/addComanyTable",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/add_company_particular_table", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: `company table added successfully` });
      return response.data;
    } else {
      notification.error(`Something went wrong!`);
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getComanyParticularTableById = createAsyncThunk(
  "companyAddTable/getComanyParticularTableById",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/company_particular_table_by_id/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      notification.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getComanyParticularTableByCompanyId = createAsyncThunk(
  "companyAddTable/getComanyParticularTableByCompanyId",
  async (company_id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/company_particular_table_by_company_id/${company_id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      notification.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
