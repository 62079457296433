import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { message, notification } from "antd";

export const scrapeCompanies = createAsyncThunk(
  "company/scrapeCompanies",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/scrape_companies", {})
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: `Sector added successfully` });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/add_company", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: `Sector added successfully` });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getCompanies = createAsyncThunk(
  "company/getCompanies",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/get_all_companies")
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_company`, { company: params })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "company/deleteCompany",
  async (params, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_company/${params.id}`, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "company deleted successfully" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteAllCompanies = createAsyncThunk(
  "company/deleteAllCompanies",
  async (params, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_all_companies`, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "all companies deleted successfully" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getCompanyInfo = createAsyncThunk(
  "company/getCompanyInfo",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/get_company_info_by_company_id/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addCompanyInfo = createAsyncThunk(
  "company/addCompanyInfo",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/admin/add_company_imp_info`, params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      message.success(response.data.message);
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateCompanyInfoReq = createAsyncThunk(
  "company/updateCompanyInfoReq",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_company_imp_info/${params.id}`, params.data)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      message.success(response.data.message);
      return response.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteCompanyInfo = createAsyncThunk(
  "company/deleteCompanyInfo",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_company_info/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      message.success(response.data.message);
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
