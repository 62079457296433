import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const addQuarter = createAsyncThunk(
  "quarters/addQuarter",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/add_quarter", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: `Quarter added successfully` });
      return response.data;
    } else {
      notification.error(`Something went wrong!`);
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getQuarters = createAsyncThunk(
  "quarters/getQuarters",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/get_all_quarters", {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateQuarter = createAsyncThunk(
  "quarters/updateQuarter",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_quarter/${params.id}`, params.data, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteQuarter = createAsyncThunk(
  "quarters/deleteQuarter",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_quarter/${id}`, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "Quarter deleted successfully" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
