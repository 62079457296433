import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  addComanyTable,
  getComanyParticularTableById,
  getComanyParticularTableByCompanyId,
} from "./companyAddTableThunk";

const initialState = {
  addCompanyTableRes: { status: "" },
  companyTableByIdRes: { status: "", data: null },
  comanyTableByCompanyId: { status: "", data: [] },
};

export const companyAddTableSlice = createSlice({
  name: "companyAddTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addComanyTable.pending.toString(), (state) => {
      return {
        ...state,
        addCompanyTableRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addComanyTable.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addCompanyTableRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addComanyTable.rejected.toString(), (state) => {
      return {
        ...state,
        addCompanyTableRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(
      getComanyParticularTableById.pending.toString(),
      (state) => {
        return {
          ...state,
          companyTableByIdRes: { status: APIstatus.IN_PROGRESS, data: [] },
        };
      }
    );
    builder.addCase(
      getComanyParticularTableById.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          companyTableByIdRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(
      getComanyParticularTableById.rejected.toString(),
      (state) => {
        return {
          ...state,
          companyTableByIdRes: { status: APIstatus.FAILURE, data: [] },
        };
      }
    );

    builder.addCase(
      getComanyParticularTableByCompanyId.pending.toString(),
      (state) => {
        return {
          ...state,
          comanyTableByCompanyId: { status: APIstatus.IN_PROGRESS, data: [] },
        };
      }
    );
    builder.addCase(
      getComanyParticularTableByCompanyId.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          comanyTableByCompanyId: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(
      getComanyParticularTableByCompanyId.rejected.toString(),
      (state) => {
        return {
          ...state,
          comanyTableByCompanyId: { status: APIstatus.FAILURE, data: [] },
        };
      }
    );
  },
});
