import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  getAnnualReportsByCompanyID,
  getAnnualReportsSection,
  addAnnualReport,
  reorderReports,
  updateAnnualReport,
  deleteReport,
} from "./reportThunk";

const initialState = {
  annualReportsByCompanyIdRes: { status: 0, data: [] },
  annualReportsSectionRes: { status: "", data: {} },
  addReportRes: { status: "", data: null },
  reorderReportRes: { status: "", data: null },
  deleteReportRes: { status: "", data: null },
  updateAnnualReportRes: { status: "", data: null },
};

export const AnnualReportSlice = createSlice({
  name: "annualReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAnnualReport.pending.toString(), (state) => {
      return {
        ...state,
        updateAnnualReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      updateAnnualReport.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateAnnualReportRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(updateAnnualReport.rejected.toString(), (state) => {
      return {
        ...state,
        updateAnnualReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(reorderReports.pending.toString(), (state) => {
      return {
        ...state,
        reorderReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(reorderReports.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        reorderReportRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(reorderReports.rejected.toString(), (state) => {
      return {
        ...state,
        reorderReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getAnnualReportsByCompanyID.pending.toString(), (state) => {
      return {
        ...state,
        annualReportsByCompanyIdRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getAnnualReportsByCompanyID.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          annualReportsByCompanyIdRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(
      getAnnualReportsByCompanyID.rejected.toString(),
      (state) => {
        return {
          ...state,
          annualReportsByCompanyIdRes: { status: APIstatus.FAILURE, data: [] },
        };
      }
    );

    builder.addCase(getAnnualReportsSection.pending.toString(), (state) => {
      return {
        ...state,
        annualReportsSectionRes: {
          status: APIstatus.IN_PROGRESS,
          data: state.annualReportsSectionRes.data,
        },
      };
    });
    builder.addCase(
      getAnnualReportsSection.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          annualReportsSectionRes: {
            status: APIstatus.SUCCESS,
            data: {
              ...state.annualReportsSectionRes.data,
              [action.payload.id]: action.payload.data.data,
            },
          },
        };
      }
    );
    builder.addCase(getAnnualReportsSection.rejected.toString(), (state) => {
      return {
        ...state,
        annualReportsSectionRes: {
          status: APIstatus.FAILURE,
          data: state.annualReportsSectionRes.data,
        },
      };
    });

    builder.addCase(addAnnualReport.pending.toString(), (state) => {
      return {
        ...state,
        addReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addAnnualReport.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addReportRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addAnnualReport.rejected.toString(), (state) => {
      return {
        ...state,
        addReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteReport.pending.toString(), (state) => {
      return {
        ...state,
        deleteReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteReport.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteReportRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(deleteReport.rejected.toString(), (state) => {
      return {
        ...state,
        deleteReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
