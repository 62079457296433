import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const addYear = createAsyncThunk(
  "years/addYear",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/add_year", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: `Year added successfully` });
      return response.data;
    } else {
      notification.error(`Something went wrong!`);
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getYears = createAsyncThunk(
  "years/getYears",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/get_all_years", {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateYear = createAsyncThunk(
  "years/updateYear",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_year/${params.id}`, params.data, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteYear = createAsyncThunk(
  "years/deleteYear",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_year/${id}`, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "Year deleted successfully" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
