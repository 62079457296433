import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  addContentCategory,
  getContentCategories,
  updateContentCategory,
  deleteContentCategory,
} from "./contentCategoryThunk";

const initialState = {
  addContentCategoryRes: { status: "", data: undefined },
  getContentCategoriesRes: { status: "", data: undefined },
  updateContentCategoryRes: { status: "", data: undefined },
  deleteContentCategoryRes: { status: "", data: undefined },
};

export const contentCategorySlice = createSlice({
  name: "contentCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addContentCategory.pending.toString(), (state) => {
      return {
        ...state,
        addContentCategoryRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      addContentCategory.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          addContentCategoryRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(addContentCategory.rejected.toString(), (state) => {
      return {
        ...state,
        addContentCategoryRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getContentCategories.pending.toString(), (state) => {
      return {
        ...state,
        getContentCategoriesRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getContentCategories.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getContentCategoriesRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getContentCategories.rejected.toString(), (state) => {
      return {
        ...state,
        getContentCategoriesRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateContentCategory.pending.toString(), (state) => {
      return {
        ...state,
        updateContentCategoryRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      updateContentCategory.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateContentCategoryRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(updateContentCategory.rejected.toString(), (state) => {
      return {
        ...state,
        updateContentCategoryRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteContentCategory.pending.toString(), (state) => {
      return {
        ...state,
        deleteContentCategoryRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      deleteContentCategory.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteContentCategoryRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(deleteContentCategory.rejected.toString(), (state) => {
      return {
        ...state,
        deleteContentCategoryRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
