import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
 
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sectors`}
          component={lazy(() => import(`./sectors`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/content-category`}
          component={lazy(() => import(`./contentCategory`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/languages`}
          component={lazy(() => import(`./languages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/years`}
          component={lazy(() => import(`./years`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/definitions`}
          component={lazy(() => import(`./definitions`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/companies`}
          component={lazy(() => import(`./companies`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/markdown`}
          component={lazy(() => import(`./markdown`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/feedbacks`}
          component={lazy(() => import(`./feedbacks`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/annual-reports`}
          component={lazy(() => import(`./annualReports`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/quarterly-reports`}
          component={lazy(() => import(`./quarterlyReports`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./user`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/quarters`}
          component={lazy(() => import(`./quarters`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/company-info`}
          component={lazy(() => import(`./companyInfo`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/particular_info`}
          component={lazy(() => import(`./particularInfo`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/backups`}
          component={lazy(() => import(`./backups`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/add_table`}
          component={lazy(() => import(`./addTable`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
