import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const getQuarterlyReportsByCompanyID = createAsyncThunk(
  "report/getQuarterlyReportsByCompanyID",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/quarter/get_quarterly_reports_by_company_id/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      // if (!response.data.data.length) {
      //   notification.error({ message: response.data.message });
      // }
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getQuarterlyReportsSection = createAsyncThunk(
  "report/getQuarterlyReportsSection",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/admin/quarter/get_quarterly_reports_section/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return { data: response.data, id };
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addQuarterlyReport = createAsyncThunk(
  "report/addQuarterlyReport",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/quarter/add_quarterly_report", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully added report" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateQuarterlyReport = createAsyncThunk(
  "report/updateQuarterlyReport",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/admin/quarter/add_quarterly_report`, params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated report" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const reorderQuarterlyReports = createAsyncThunk(
  "report/reorderQuarterlyReports",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put("/api/admin/quarter/add_order", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully reordered reports" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteQuarterlyReport = createAsyncThunk(
  "report/deleteQuarterlyReport",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/quarter/delete_quarterly_report/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully deleted report" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
