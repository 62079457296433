import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  getQuarterlyReportsByCompanyID,
  getQuarterlyReportsSection,
  addQuarterlyReport,
  reorderQuarterlyReports,
  updateQuarterlyReport,
  deleteQuarterlyReport,
} from "./quarterlyReportThunk";

const initialState = {
  quarterlyReportsByCompanyIdRes: { status: "", data: [] },
  quarterlyReportsSectionRes: { status: "", data: {} },
  addQuarterlyReportRes: { status: "", data: null },
  reorderQuarterlyReportRes: { status: "", data: null },
  updateQuarterlyReportRes: { status: "", data: null },
  deleteQuarterlyReportRes: { status: "", data: null },
};

export const quarterlyReportSlice = createSlice({
  name: "quarterlyReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateQuarterlyReport.pending.toString(), (state) => {
      return {
        ...state,
        updateQuarterlyReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      updateQuarterlyReport.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateQuarterlyReportRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(updateQuarterlyReport.rejected.toString(), (state) => {
      return {
        ...state,
        updateQuarterlyReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(reorderQuarterlyReports.pending.toString(), (state) => {
      return {
        ...state,
        reorderQuarterlyReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      reorderQuarterlyReports.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          reorderQuarterlyReportRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(reorderQuarterlyReports.rejected.toString(), (state) => {
      return {
        ...state,
        reorderQuarterlyReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(
      getQuarterlyReportsByCompanyID.pending.toString(),
      (state) => {
        return {
          ...state,
          quarterlyReportsByCompanyIdRes: {
            status: APIstatus.IN_PROGRESS,
            data: [],
          },
        };
      }
    );
    builder.addCase(
      getQuarterlyReportsByCompanyID.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          quarterlyReportsByCompanyIdRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(
      getQuarterlyReportsByCompanyID.rejected.toString(),
      (state) => {
        return {
          ...state,
          quarterlyReportsByCompanyIdRes: {
            status: APIstatus.FAILURE,
            data: [],
          },
        };
      }
    );

    builder.addCase(getQuarterlyReportsSection.pending.toString(), (state) => {
      return {
        ...state,
        quarterlyReportsSectionRes: {
          status: APIstatus.IN_PROGRESS,
          data: state.quarterlyReportsSectionRes.data,
        },
      };
    });
    builder.addCase(
      getQuarterlyReportsSection.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          quarterlyReportsSectionRes: {
            status: APIstatus.SUCCESS,
            data: {
              ...state.quarterlyReportsSectionRes.data,
              [action.payload.id]: action.payload.data.data,
            },
          },
        };
      }
    );
    builder.addCase(getQuarterlyReportsSection.rejected.toString(), (state) => {
      return {
        ...state,
        quarterlyReportsSectionRes: {
          status: APIstatus.FAILURE,
          data: state.quarterlyReportsSectionRes.data,
        },
      };
    });

    builder.addCase(addQuarterlyReport.pending.toString(), (state) => {
      return {
        ...state,
        addQuarterlyReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      addQuarterlyReport.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          addQuarterlyReportRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(addQuarterlyReport.rejected.toString(), (state) => {
      return {
        ...state,
        addQuarterlyReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteQuarterlyReport.pending.toString(), (state) => {
      return {
        ...state,
        deleteQuarterlyReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      deleteQuarterlyReport.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteQuarterlyReportRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(deleteQuarterlyReport.rejected.toString(), (state) => {
      return {
        ...state,
        deleteQuarterlyReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
