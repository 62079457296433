import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { getUsers, updateUser, resetPassword } from "./userThunk";

const initialState = {
  usersListRes: { status: "", data: null },
  updateUserRes: { status: "", data: null },
  resetPasswordRes: { status: "", data: null },
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending.toString(), (state) => {
      return {
        ...state,
        usersListRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getUsers.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        usersListRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getUsers.rejected.toString(), (state) => {
      return {
        ...state,
        usersListRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateUser.pending.toString(), (state) => {
      return {
        ...state,
        updateUserRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateUser.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateUserRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(updateUser.rejected.toString(), (state) => {
      return {
        ...state,
        updateUserRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(resetPassword.pending.toString(), (state) => {
      return {
        ...state,
        resetPasswordRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(resetPassword.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        resetPasswordRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(resetPassword.rejected.toString(), (state) => {
      return {
        ...state,
        resetPasswordRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
