import React, { Component } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Drawer, Menu, Button, Modal, Form, Input } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { DIR_RTL } from "constants/ThemeConstant";
import { withRouter } from "react-router-dom";
import { changeAdminPassword } from "redux/toolkit/auth/authThunk";
import APIstatus from "redux/constants/common";

export class NavPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      passwordModalVisible: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.changeAdminPasswordRes.status !==
      this.props.changeAdminPasswordRes.status
    ) {
      if (this.props.changeAdminPasswordRes.status === APIstatus.SUCCESS) {
        this.handlePasswordModalCancel();
      }
    }
  }

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  signOut = () => {
    localStorage.removeItem("authToken");
    this.props.history.push("/auth/login");
  };

  showPasswordModal = () => {
    this.setState({ passwordModalVisible: true });
  };

  handlePasswordModalCancel = () => {
    this.setState({ passwordModalVisible: false });
  };

  handlePasswordSubmit = (values) => {
    this.props.changeAdminPassword({
      current_password: values.currentPassword,
      new_password: values.confirmNewPassword,
    });
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          <Menu.Item key="changePassword">
            <Button onClick={this.showPasswordModal}>Change Password</Button>
          </Menu.Item>
          <Menu.Item key="logout">
            <Button onClick={this.signOut}>Sign Out</Button>
          </Menu.Item>
          <Menu.Item key="panel" onClick={this.showDrawer}>
            <span>
              <SettingOutlined className="nav-icon mr-0" />
            </span>
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement={this.props.direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
        <Modal
          title="Change Password"
          visible={this.state.passwordModalVisible}
          onCancel={this.handlePasswordModalCancel}
          footer={null}
        >
          <Form layout="vertical" onFinish={this.handlePasswordSubmit}>
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your current password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: "Please enter your new password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirmNewPassword"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={
                  this.props.changeAdminPasswordRes.status ===
                  APIstatus.IN_PROGRESS
                }
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.theme;
  const { changeAdminPasswordRes } = state.auth;
  return { locale, changeAdminPasswordRes };
};

const mapDispatchToProps = {
  changeAdminPassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavPanel)
);
