import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  addCompany,
  getCompanies,
  updateCompany,
  deleteCompany,
  scrapeCompanies,
  getCompanyInfo,
  addCompanyInfo,
  deleteCompanyInfo,
  deleteAllCompanies,
  updateCompanyInfoReq,
} from "./companyThunk";

const initialState = {
  companyAddRes: { status: "", data: undefined },
  getCompanyStatus: { status: "", data: [] },
  updateCompanyStatus: { status: "", data: undefined },
  deleteCompanyRes: { status: "", data: undefined },
  scrapeCompanies: { status: "", data: undefined },
  getCompanyInfoRes: { status: "", data: [] },
  addCompanyInfoRes: { status: "", data: undefined },
  deleteCompanyInfoRes: { status: "", data: undefined },
  deleteAllCompaniesRes: { status: "", data: undefined },
  updateCompanyInfoRes: { status: "", data: [] },
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateCompanyInfoReq.pending.toString(), (state) => {
      return {
        ...state,
        updateCompanyInfoRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      updateCompanyInfoReq.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateCompanyInfoRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(updateCompanyInfoReq.rejected.toString(), (state) => {
      return {
        ...state,
        updateCompanyInfoRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteAllCompanies.pending.toString(), (state) => {
      return {
        ...state,
        deleteAllCompaniesRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      deleteAllCompanies.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteAllCompaniesRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(deleteAllCompanies.rejected.toString(), (state) => {
      return {
        ...state,
        deleteAllCompaniesRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(scrapeCompanies.pending.toString(), (state) => {
      return {
        ...state,
        scrapeCompanies: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(scrapeCompanies.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        scrapeCompanies: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(scrapeCompanies.rejected.toString(), (state) => {
      return {
        ...state,
        scrapeCompanies: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addCompany.pending.toString(), (state) => {
      return {
        ...state,
        companyAddRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addCompany.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        companyAddRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addCompany.rejected.toString(), (state) => {
      return {
        ...state,
        companyAddRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getCompanies.pending.toString(), (state) => {
      return {
        ...state,
        getCompanyStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getCompanies.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getCompanyStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload.data.length ? action.payload.data : [],
        },
      };
    });
    builder.addCase(getCompanies.rejected.toString(), (state) => {
      return {
        ...state,
        getCompanyStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateCompany.pending.toString(), (state) => {
      return {
        ...state,
        updateCompanyStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateCompany.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateCompanyStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updateCompany.rejected.toString(), (state) => {
      return {
        ...state,
        updateCompanyStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteCompany.pending.toString(), (state) => {
      return {
        ...state,
        deleteCompanyRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteCompany.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteCompanyRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteCompany.rejected.toString(), (state) => {
      return {
        ...state,
        deleteCompanyRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getCompanyInfo.pending.toString(), (state) => {
      return {
        ...state,
        getCompanyInfoRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getCompanyInfo.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getCompanyInfoRes: {
          status: APIstatus.SUCCESS,
          data: Array.isArray(action.payload) ? action.payload : [],
        },
      };
    });
    builder.addCase(getCompanyInfo.rejected.toString(), (state) => {
      return {
        ...state,
        getCompanyInfoRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addCompanyInfo.pending.toString(), (state) => {
      return {
        ...state,
        addCompanyInfoRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addCompanyInfo.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addCompanyInfoRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(addCompanyInfo.rejected.toString(), (state) => {
      return {
        ...state,
        addCompanyInfoRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteCompanyInfo.pending.toString(), (state) => {
      return {
        ...state,
        deleteCompanyInfoRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteCompanyInfo.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteCompanyInfoRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteCompanyInfo.rejected.toString(), (state) => {
      return {
        ...state,
        deleteCompanyInfoRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
