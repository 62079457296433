import axios from "axios";
import tokenService from "./tokenService";

function getAuth() {
  return `Bearer ${tokenService.getToken()}`;
}

// let baseURL = "http://127.0.0.1:5000/";
let baseURL = "https://dev.sumgya.com/";

// Uncomment and adjust these lines based on your environment setup
// if (import.meta.env.VITE_MODE === "development") {
//   baseURL = "https://bravo.castor.letsaspire.in";
// } else if (import.meta.env.VITE_MODE === "production") {
//   baseURL = "https://bravo.atlas.letsaspire.in";
// } else {
//   baseURL = "https://bravo.castor.letsaspire.in";
// }

const secureApi = axios.create({
  baseURL: baseURL,
  headers: {
    // Authorization: getAuth(),
  },
});

// Add a request interceptor
secureApi.interceptors.request.use(
  function (config) {
    // Uncomment and adjust these lines based on your specific requirements
    // if (config.method === "post") {
    //   if (config.data.append) {
    //     // This means it is formdata
    //     config.data.append("version_code", "web_version");
    //   } else {
    //     config.data = {
    //       ...config.data,
    //       version_code: "web_version",
    //     };
    //   }
    // } else {
    //   config.params = {
    //     ...config.params,
    //     version_code: "web_version",
    //   };
    // }
    config.headers.Authorization = getAuth();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
secureApi.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      tokenService.deleteToken();
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      tokenService.deleteToken();
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  function (config) {
    // Uncomment and adjust these lines based on your specific requirements
    // if (config.method === "post") {
    //   if (config.data.append) {
    //     // This means it is formdata
    //     config.data.append("version_code", "web_version");
    //   } else {
    //     config.data = {
    //       ...config.data,
    //       version_code: "web_version",
    //     };
    //   }
    // } else {
    //   config.params = {
    //     ...config.params,
    //     version_code: "web_version",
    //   };
    // }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      tokenService.deleteToken();
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      tokenService.deleteToken();
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default secureApi;
