import { createSlice } from "@reduxjs/toolkit";
import {
  addDefinitionReq,
  deleteDefinitionReq,
  updateDefinitionReq,
  fetchAllDefinitionReq,
} from "./definitionThunk";
import APIstatus from "redux/constants/common";

const initialState = {
  addDefinitionRes: { status: "", data: null },
  deleteDefinitionRes: { status: "", data: null },
  updateDefinitionRes: { status: "", data: null },
  fetchAllDefinitionRes: { status: "", data: null },
};

export const definitionSlice = createSlice({
  name: "definition",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDefinitionReq.pending.toString(), (state) => {
      return {
        ...state,
        fetchAllDefinitionRes: { status: APIstatus.IN_PROGRESS, data: null },
      };
    });
    builder.addCase(
      fetchAllDefinitionReq.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          fetchAllDefinitionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(fetchAllDefinitionReq.rejected.toString(), (state) => {
      return {
        ...state,
        fetchAllDefinitionRes: { status: APIstatus.FAILURE, data: null },
      };
    });

    builder.addCase(addDefinitionReq.pending.toString(), (state) => {
      return {
        ...state,
        addDefinitionRes: { status: APIstatus.IN_PROGRESS, data: null },
      };
    });
    builder.addCase(addDefinitionReq.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addDefinitionRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addDefinitionReq.rejected.toString(), (state) => {
      return {
        ...state,
        addDefinitionRes: { status: APIstatus.FAILURE, data: null },
      };
    });

    builder.addCase(updateDefinitionReq.pending.toString(), (state) => {
      return {
        ...state,
        updateDefinitionRes: { status: APIstatus.IN_PROGRESS, data: null },
      };
    });
    builder.addCase(
      updateDefinitionReq.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateDefinitionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(updateDefinitionReq.rejected.toString(), (state) => {
      return {
        ...state,
        updateDefinitionRes: { status: APIstatus.FAILURE, data: null },
      };
    });

    builder.addCase(deleteDefinitionReq.pending.toString(), (state) => {
      return {
        ...state,
        deleteDefinitionRes: { status: APIstatus.IN_PROGRESS, data: null },
      };
    });
    builder.addCase(
      deleteDefinitionReq.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteDefinitionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(deleteDefinitionReq.rejected.toString(), (state) => {
      return {
        ...state,
        deleteDefinitionRes: { status: APIstatus.FAILURE, data: null },
      };
    });
  },
});

// export const {} = definitionSlice.actions;
