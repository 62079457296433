import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { addQuarter, getQuarters, updateQuarter, deleteQuarter } from "./quartersThunk";

const initialState = {
  quarterAddStatus: { status: "", data: undefined },
  getQuartersStatus: { status: "", data: undefined },
  updateQuarterStatus: { status: "", data: undefined },
  deleteQuarterStatus: { status: "", data: undefined },
};

export const quarterSlice = createSlice({
  name: "quarters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addQuarter.pending.toString(), (state) => {
      return {
        ...state,
        quarterAddStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addQuarter.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        quarterAddStatus: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addQuarter.rejected.toString(), (state) => {
      return {
        ...state,
        quarterAddStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getQuarters.pending.toString(), (state) => {
      return {
        ...state,
        getQuartersStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getQuarters.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getQuartersStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getQuarters.rejected.toString(), (state) => {
      return {
        ...state,
        getQuartersStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateQuarter.pending.toString(), (state) => {
      return {
        ...state,
        updateQuarterStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateQuarter.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateQuarterStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updateQuarter.rejected.toString(), (state) => {
      return {
        ...state,
        updateQuarterStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteQuarter.pending.toString(), (state) => {
      return {
        ...state,
        deleteQuarterStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteQuarter.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteQuarterStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteQuarter.rejected.toString(), (state) => {
      return {
        ...state,
        deleteQuarterStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
