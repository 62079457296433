import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { addYear, getYears, updateYear, deleteYear } from "./yearsThunk";

const initialState = {
  yearAddStatus: { status: "", data: undefined },
  getYearsStatus: { status: "", data: undefined },
  updateYearStatus: { status: "", data: undefined },
  deleteYearStatus: { status: "", data: undefined },
};

export const yearSlice = createSlice({
  name: "years",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addYear.pending.toString(), (state) => {
      return {
        ...state,
        yearAddStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addYear.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        yearAddStatus: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addYear.rejected.toString(), (state) => {
      return {
        ...state,
        yearAddStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getYears.pending.toString(), (state) => {
      return {
        ...state,
        getYearsStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getYears.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getYearsStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getYears.rejected.toString(), (state) => {
      return {
        ...state,
        getYearsStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateYear.pending.toString(), (state) => {
      return {
        ...state,
        updateYearStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateYear.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateYearStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updateYear.rejected.toString(), (state) => {
      return {
        ...state,
        updateYearStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteYear.pending.toString(), (state) => {
      return {
        ...state,
        deleteYearStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteYear.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteYearStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteYear.rejected.toString(), (state) => {
      return {
        ...state,
        deleteYearStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
