import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/get_users", {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_user/${params.id}`, params.data, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/admin/change_password`, params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "successfully updated" });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
