import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { login, changeAdminPassword } from "./authThunk";

const initialState = {
  loginRes: { status: "", data: undefined },
  signUpRes: { status: "", data: undefined },
  changeAdminPasswordRes: { status: "", data: undefined },
  otherLoader: false,
  token: "",
  authMessage: "",
};

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setOtherLoader: (state, action) => {
      state.otherLoader = action.payload;
    },
    authenticatedToken: (state, action) => {
      state.token = action.payload;
    },
    setShowAuthMessage: (state, action) => {
      state.authMessage = action.payload;
    },
    setHideAuthMessage: (state, action) => {
      state.authMessage = action.payload;
    },
    setSignOutSuccess: (state, action) => {
      state.token = action.payload;
    },
    setSignUpSuccess: (state, action) => {
      state.token = action.payload.token;
    },
    setSignInWithGoogleAuthenticated: (state, action) => {
      state.token = action.payload.token;
    },
    setSignInWithFacebookAuthenticated: (state, action) => {
      state.token = action.payload.token;
    },
    signOut: (state, action) => {
      state.token = null;
      localStorage.removeItem("");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending.toString(), (state) => {
      return {
        ...state,
        loginRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(login.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        loginRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(login.rejected.toString(), (state) => {
      return {
        ...state,
        loginRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(changeAdminPassword.pending.toString(), (state) => {
      return {
        ...state,
        changeAdminPasswordRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      changeAdminPassword.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          changeAdminPasswordRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(changeAdminPassword.rejected.toString(), (state) => {
      return {
        ...state,
        changeAdminPasswordRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    // builder.addCase(signUp.pending.toString(), (state) => {
    //   return {
    //     ...state,
    //     signUpRes: { status: APIstatus.IN_PROGRESS, data: [] },
    //   };
    // });
    // builder.addCase(signUp.fulfilled.toString(), (state, action) => {
    //   return {
    //     ...state,
    //     signUpRes: { status: APIstatus.SUCCESS, data: action.payload },
    //   };
    // });
    // builder.addCase(signUp.rejected.toString(), (state) => {
    //   return {
    //     ...state,
    //     signUpRes: { status: APIstatus.FAILURE, data: [] },
    //   };
    // });
  },
});

export const {
  setOtherLoader,
  authenticatedToken,
  setShowAuthMessage,
  setHideAuthMessage,
  setSignOutSuccess,
  setSignUpSuccess,
  setSignInWithGoogleAuthenticated,
  setSignInWithFacebookAuthenticated,
} = authSlice.actions;
