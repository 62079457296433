import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";

export const addContentCategory = createAsyncThunk(
  "contentCategory/addContentCategory",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/admin/add_content_category", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: `Category added successfully` });
      return response.data;
    } else {
      notification.success({ message: `There is some error!` });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getContentCategories = createAsyncThunk(
  "contentCategory/getContentCategories",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/api/admin/get_all_content_category", {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateContentCategory = createAsyncThunk(
  "contentCategory/updateContentCategory",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/admin/update_content_category/${params.id}`, params.data, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "Successfully Updated" });
      return response.data;
    } else {
      notification.error({ message: response.data.message });
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteContentCategory = createAsyncThunk(
  "contentCategory/deleteContentCategory",
  async (params, thunkAPI) => {
    const response = await secureApi
      .delete(`/api/admin/delete_content_category/${params.id}`, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      notification.success({ message: "Category deleted successfully" });
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
