import { combineReducers } from "redux";
import { themeSlice } from "./theme/themeSlice";
import { authSlice } from "./auth/authSlice";
import { sectorSlice } from "./sector/sectorSlice";
import { companySlice } from "./companies/companySlice";
import { AnnualReportSlice } from "./reports/reportSlice";
import { quarterlyReportSlice } from "./quarterlyReports/quarterlyReportSlice";
import { feedbackSlice } from "./feedbacks/feedbackSlice";
import { languageSlice } from "./languages/languageSlice";
import { yearSlice } from "./years/yearsSlice";
import { quarterSlice } from "./quarters/quartersSlice";
import { userSlice } from "./user/userSlice";
import { backupSlice } from "./backups/backupSlice";
import { definitionSlice } from "./definition/definitionSlice";
import { contentCategorySlice } from "./contentCategory/contentCategorySlice";
import { particularInfoSlice } from "./particularInfo/particularInfoSlice";
import { companyAddTableSlice } from "./companyAddTable/companyAddTableSlice";

const reducer = combineReducers({
  theme: themeSlice.reducer,
  auth: authSlice.reducer,
  sector: sectorSlice.reducer,
  company: companySlice.reducer,
  annualReports: AnnualReportSlice.reducer,
  quarterlyReports: quarterlyReportSlice.reducer,
  feedbacks: feedbackSlice.reducer,
  language: languageSlice.reducer,
  years: yearSlice.reducer,
  users: userSlice.reducer,
  quarters: quarterSlice.reducer,
  backups: backupSlice.reducer,
  definitions: definitionSlice.reducer,
  contentCategory: contentCategorySlice.reducer,
  particularInfo: particularInfoSlice.reducer,
  companyAddTable: companyAddTableSlice.reducer,
});

export default reducer;
