import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  addSector,
  getSectors,
  updateSector,
  deleteSector,
} from "./sectorThunk";

const initialState = {
  sectorAddStatus: { status: "", data: undefined },
  getSectorRes: { status: "", data: [] },
  updateSectorStatus: { status: "", data: undefined },
  deleteSectorStatus: { status: "", data: undefined },
};

export const sectorSlice = createSlice({
  name: "sector",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSector.pending.toString(), (state) => {
      return {
        ...state,
        sectorAddStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addSector.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        sectorAddStatus: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(addSector.rejected.toString(), (state) => {
      return {
        ...state,
        sectorAddStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getSectors.pending.toString(), (state) => {
      return {
        ...state,
        getSectorRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getSectors.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSectorRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(getSectors.rejected.toString(), (state) => {
      return {
        ...state,
        getSectorRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateSector.pending.toString(), (state) => {
      return {
        ...state,
        updateSectorStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateSector.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateSectorStatus: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(updateSector.rejected.toString(), (state) => {
      return {
        ...state,
        updateSectorStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteSector.pending.toString(), (state) => {
      return {
        ...state,
        deleteSectorStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteSector.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteSectorStatus: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(deleteSector.rejected.toString(), (state) => {
      return {
        ...state,
        deleteSectorStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
