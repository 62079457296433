import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";

import { getFeedbacks, updateFeedback } from "./feedbackThunk";

const initialState = {
  getFeedbacksStatus: { status: undefined, data: [] },
  updateFeedbackStatus: { status: undefined, data: [] },
};

export const feedbackSlice = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeedbacks.pending.toString(), (state) => {
      return {
        ...state,
        getFeedbacksStatus: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getFeedbacks.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getFeedbacksStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getFeedbacks.rejected.toString(), (state) => {
      return {
        ...state,
        getFeedbacksStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateFeedback.pending.toString(), (state) => {
      return {
        ...state,
        updateFeedbackStatus: { status: APIstatus.pending, data: [] },
      };
    });
    builder.addCase(updateFeedback.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateFeedbackStatus: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updateFeedback.rejected.toString(), (state) => {
      return {
        ...state,
        updateFeedbackStatus: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
